import React from "react"
import "./modal.css"
class Modal extends React.Component {
  ready = false
  componentDidMount() {
    if (this.ready) return
    this.ready = true
    console.log("componentDidMount")
    document.body.appendChild(document.getElementById("confirm-modal"))
    window.addEventListener("click", e => {
      var modal = document.querySelector(".modal")
      if (e.target === modal) this.props.close()
    })
  }

  componentWillUnmount() {
   
  }
  render() {
    return (
      <div id="confirm-modal">
        <div class={`modal ${this.props.open ? "show-modal" : ""}`}>
          <div class="modal-content">
            <p className="form-success">
              Your information has been received, and a Neon Coat representative
              will be in contact with you soon!
            </p>
            <div
              className="contact-submit-button"
              style={{margin: "auto", marginTop: '25px'}}
              onClick={() => this.props.close()}
            >
              OK
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Modal
