import React from "react"
import fetchPonyfill from "fetch-ponyfill"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./contact.css"
import miami from "../images/miami.svg"
import losangeles from "../images/losangeles.svg"
import london from "../images/london.svg"
import newyork from "../images/newyork.svg"
import global from "../images/global.svg"
import Modal from "../components/modal"

const ContactIcon = ({ icon, title, big = false }) => (
  <div
    className="col"
    style={{
      display: "flex",
      alignItems: "flex-end",
    }}
  >
    <div style={{ margin: "0px auto" }}>
      <span style={{ display: "inline-block" }}>
        <img
          src={icon}
          alt={title}
          className="contact-icon"
          style={big ? { width: "100%" } : {}}
        />
      </span>
      <div className="contact-icon-text">{title}</div>
    </div>
  </div>
)

const Icons = ({ desktop }) => (
  <>
    <div
      className={`flex-grid icons-grid ${
        desktop ? "only-desktop-tablet" : "only-mobile"
      }`}
    >
      <ContactIcon icon={newyork} title="NEW YORK" />
      <ContactIcon icon={losangeles} title="LOS ANGELES" big={true} />
      <ContactIcon icon={miami} title="MIAMI" />
      <ContactIcon icon={london} title="LONDON" />
      <ContactIcon icon={global} title="+ BEYOND" />
    </div>
    {desktop ? null : (
      <>
        <br />
        <br />
        <br />
      </>
    )}
  </>
)

const { fetch } = fetchPonyfill()

class ContactPage extends React.Component {
  state = {
    data: { name: "", text: "", email: "" },
    errors: {},
    error: null,
    sent: false,
    modal: false,
    sending: false
  }

  closeModal() {
    this.setState({ modal: false, data: { name: "", text: "", email: "" } })
  }

  submit() {
    if(this.state.sending) return;
    this.setState({ errors: {}, error: "", sent: false })
    const errors = {}
    if (!this.state.data.name) {
      errors["name"] = "Name is required!"
    }
    if (!this.state.data.text) {
      errors["text"] = "Comment is required!"
    }
    if (!this.state.data.email) {
      errors["email"] = "Email is required!"
    } else if (!/\S+@\S+\.\S+/.test(this.state.data.email)) {
      errors["email"] = "Email format not correct!"
    }
    if (Object.keys(errors).length > 0) {
      this.setState({ errors })
      return
    }

    this.setState({sending: true});

    fetch(`${process.env.GATSBY_API_URL}/website/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.state.data),
    })
      .then(response => {
        console.log(response)
        if (!response.ok) this.setState({ error: "An error occurred" })
        if (response.ok) this.setState({ sent: true, sending:false })
      })
      .catch(error => this.setState({ error: "An error occurred", sending: false }))
  }

  setField(name, e) {
    const value = e.target.value
    this.setState(state => {
      return {
        ...state,
        data: { ...state.data, [name]: value },
      }
    })
  }

  render() {
    return (
      <Layout page="contact">
        <SEO title="Contact" description="Wanna know more? Get in touch." />
        <div className="flex-grid" style={{ backgroundColor: "#b7c6d2" }}>
          <div style={{ flex: "0 1 1000px" }}>
            <Icons desktop={true} />
            <div className="contact-question-container">
              <div className="share-out-story" style={{ marginTop: "0px" }}>
                Have a question? Get in touch.
              </div>
              <div className="share-out-story-small">
                E-mail:{" "}
                <a
                  href="mailto:info@neoncoat.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  info@neoncoat.com
                </a>
                <br />
                Phone: <a href="tel:+13477099134">(347) 709-9134</a>
                <br />
                Or enter your info below.
              </div>
            </div>
          </div>
        </div>
        <div className="flex-grid contact-form">
          <div className="contact-col">
            <div className="only-tablet">
              <div className="share-out-story" style={{ marginTop: "0px" }}>
                Have a question? Get in touch.
              </div>
              <div className="contact-details">
                E-mail:{" "}
                <a
                  href="mailto:info@neoncoat.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  info@neoncoat.com
                </a>
                <br />
                Phone: <a href="tel:+13477099134">(347) 709-9134</a>
                <br />
                Or enter your info below.
              </div>
            </div>
            {!this.state.sent ? (
              <div className="flex-grid contact-form-grid">
                <div className="col xx">
                  <div className="contact-form-label">
                    Full Name (First + Last)
                    {this.state.errors["name"] ? (
                      <span className="form-error">
                        {this.state.errors["name"]}
                      </span>
                    ) : null}
                  </div>
                  <input
                    type="text"
                    className="contact-form-input contact-small-input"
                    placeholder="Alex Smith"
                    tabIndex="1"
                    value={this.state.data.name}
                    onChange={e => this.setField("name", e)}
                  ></input>
                  <div className="only-desktop">
                    <div className="contact-form-label">
                      Comment/Inquiry
                      {this.state.errors["text"] ? (
                        <span className="form-error">
                          {this.state.errors["text"]}
                        </span>
                      ) : null}
                    </div>
                    <textarea
                      className="contact-form-input contact-small-input"
                      style={{ height: "100px" }}
                      placeholder="What's on your mind?"
                      tabIndex="3"
                      onChange={e => this.setField("text", e)}
                      value={this.state.data.text}
                    ></textarea>
                  </div>
                </div>
                <div className="col">
                  <div className="contact-form-label">
                    E-mail Address
                    {this.state.errors["email"] ? (
                      <span className="form-error">
                        {this.state.errors["email"]}
                      </span>
                    ) : null}
                  </div>
                  <input
                    type="email"
                    className="contact-form-input contact-small-input"
                    style={{ marginBottom: "30px" }}
                    placeholder="alex@example.com"
                    value={this.state.data.email}
                    onChange={e => this.setField("email", e)}
                    tabIndex="2"
                  ></input>
                  <div className="only-mobile-tablet">
                    <div
                      className="contact-form-label"
                      style={{ marginTop: "-5px" }}
                    >
                      Comment/Inquiry
                      {this.state.errors["text"] ? (
                        <span className="form-error">
                          {this.state.errors["text"]}
                        </span>
                      ) : null}
                    </div>
                    <textarea
                      className="contact-form-input contact-small-input"
                      style={{ height: "100px" }}
                      placeholder="What's on your mind?"
                      onChange={e => this.setField("text", e)}
                      value={this.state.data.text}
                    ></textarea>
                  </div>
                  <div
                    className="contact-submit-button"
                    onClick={() => this.submit()}
                  >
                    {this.state.sending ? "SENDING..." : "SUBMIT"}
                  </div>
                  <Modal
                    open={this.state.modal}
                    close={() => this.closeModal()}
                  />
                  {this.state.error ? (
                    <>
                      <br />
                      <span className="form-success" style={{ color: "red" }}>
                        {this.state.error}
                      </span>
                    </>
                  ) : null}
                </div>
              </div>
            ) : (
              <div className="contact-message-sent">
                Your information has been received, and a Neon Coat
                representative will be in contact with you soon!
              </div>
            )}
          </div>
        </div>
        <Icons desktop={false} />
      </Layout>
    )
  }
}

export default ContactPage
